import Loader from "./loader";

export default function Fallback() {
    let dark = true;
    try {
        dark = window.matchMedia('(prefers-color-scheme: dark)').matches
    } catch { }

    return (
        <div className={`min-h-screen ${dark ? "dark" : "light"}`}>
            <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
                <Loader />
            </div>
        </div>
    );
}