import { createBrowserRouter, useNavigate } from "react-router";
import Layout from "./components/Layout";
import { Component as Error404 } from "./components/pages/codes/404";
import { SessionStore } from "@/lib/stores";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import ErrorBoundary from "@/components/ui/custom/errorboundary";
import Fallback from "./components/ui/custom/fallback";

export default createBrowserRouter([
    {
        path: "/",
        element: (
            <ErrorBoundary>
                <Layout store={SessionStore} />
            </ErrorBoundary>
        ),
        id: "root",
        HydrateFallback: () => <Fallback />,
        children: [
            {
                index: true,
                async lazy() {
                    let Home = (await import("./components/pages/Home")).Component
                    return { Component: () => <Home store={SessionStore} /> };
                },
                id: "home",
            },
            {
                path: "/contact",
                async lazy() {
                    let Contact = (await import("./components/pages/Contact")).Component
                    return { Component: () => <Contact store={SessionStore} /> };
                },
                id: "contact",
            },
            {
                path: "/team",
                async lazy() {
                    let Team = (await import("./components/pages/Team")).Component
                    return { Component: () => <Team store={SessionStore} /> };
                },
                id: "team",
            },
            {
                path: "*",
                element: <Error404 />,
                id: "404",
            },
        ],
    },
], {
    future: {}
});