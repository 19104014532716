import { Button } from "@/components/ui/button";
import { Menu, X, Moon, Sun } from "lucide-react";
import { AbortedDeferredError, NavLink } from "react-router";
import { runInAction, set } from "mobx";
import { observer } from "mobx-react-lite";
import { toast } from "sonner";
import { Website } from "@/lib/settings";
import { ThemeButton } from "@/lib/providers/theme";

export const NavLinks = [
    { key: "home", label: "Home", path: "/" },
    { key: "contact", label: "Contact", path: "/contact" },
    { key: "team", label: "Team", path: "/team" },
];

export function getNavLink(key) {
    return NavLinks.find((link) => link.key === key);
}

const Nav = observer(({ options, store, toggles }) => {
    return (
        <div className="flex items-center space-x-4">
            <nav className="hidden md:block">
                <ul className="flex space-x-4">
                    {
                        NavLinks.map((link) => {
                            return ((!("enabled" in link) || link.enabled({ store })) && (
                                <li key={link.key}>
                                    <NavLink
                                        className={
                                            ({ isActive }) => {
                                                return `hover:underline text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white ${isActive ? "underline font-bold text-primary dark:text-primary" : ""}`
                                            }
                                        }
                                        to={link.path}
                                        onClick={() => {
                                            link.onClick?.(store)
                                        }}
                                        state={{ active: link.key }}
                                        target={link.target || "_self"}
                                        rel={link.rel || ""}
                                    >
                                        <span>
                                            {link.label}
                                        </span>
                                    </NavLink>
                                </li>
                            ) || null)
                        })
                    }
                </ul>
            </nav>
            <Button
                variant="ghost"
                size="icon"
                className="text-gray-600 md:hidden dark:text-gray-300"
                onClick={toggles.mobileMenu}
                aria-label="Toggle mobile menu"
            >
                {
                    options.mobileMenuOpen ?
                        <X className="w-5 h-5" />
                        :
                        <Menu className="w-5 h-5" />
                }
            </Button>
            <ThemeButton />
        </div >
    );
});

const MobileNav = observer(({ options, store, toggles }) => {
    return options.mobileMenuOpen && (
        <nav className="z-20 bg-white shadow-lg md:hidden dark:bg-gray-800">
            <ul className="py-2" onClick={() => toggles.mobileMenu()}>
                {
                    NavLinks.map((link) => (!("enabled" in link) || link.enabled({ store })) && (
                        <li key={link.key}>
                            <NavLink
                                className={
                                    ({ isActive }) => {
                                        return `block px-2 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 ${isActive ? "underline font-bold text-primary dark:text-primary bg-gray-100 dark:bg-gray-700" : ""}`
                                    }
                                }
                                to={link.path}
                                onClick={() => {
                                    runInAction(() => {
                                        store.Active = link.key
                                    })
                                    link.onClick?.(store)
                                }}
                                target={link.target || "_self"}
                                rel={link.rel || ""}
                            >
                                {link.label}
                            </NavLink>
                        </li>
                    ))
                }
            </ul>
        </nav>
    )
});

export {
    Nav,
    MobileNav
}