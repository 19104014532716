import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, RouterProvider } from "react-router/dom";
import "./assets/css/main.scss";
import routes from "./routes";
import { ThemeProvider } from "@/lib/providers/theme";
import { toJS } from "mobx";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <ThemeProvider defaultTheme="system">
            <RouterProvider router={routes} />
        </ThemeProvider>
    </React.StrictMode>
);